import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import Newsletter from "./NewsLetter";
import { Link } from "react-router-dom";

const colorLogoImage = require("../assets/images/color-logo.png");

const socialLinks = [
  { id: 1, link: "#", icon: "lab la-facebook" },
  { id: 2, link: "#", icon: "lab la-whatsapp" },
  { id: 3, link: "#", icon: "lab la-twitter" },
  { id: 4, link: "#", icon: "lab la-linkedin" },
  { id: 5, link: "#", icon: "lab la-instagram" },
];

const quickLinks = [
  { id: 1, link: "#", item: "Home" },
  { id: 2, link: "#", item: "About" },
  { id: 3, link: "#", item: "Blogs" },
  { id: 4, link: "#", item: "Pricing" },
  { id: 5, link: "#", item: "Download" },
  { id: 6, link: "#", item: "Features" },
];

const usefulLinks = [
  { id: 3, link: "#", item: "Terms & Conditions" },
  { id: 4, link: "#", item: "Privacy Policy" },
];

const Footer = () => {
  return (
    <>
      <footer className="sp-b">
        <Container>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
