import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { getString } from "../firebase";
import axios from "axios";

const template = "{MESSAGE}<br/>{NAME} {SURNAME}<br/>{EMAIL}";

const ContactForm = () => {
  const [postError, setPostError] = useState("");

  const [postSuccess, setPostSuccess] = useState("");

  const [contactFormData, setContactFormData] = useState({
    firstName: "",
    lastname: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setContactFormData((preValues) => {
      setPostError("");
      return {
        ...preValues,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPostError("");
    setPostSuccess("");
    if (contactFormData.email && contactFormData.message) {
      if (!validateEmail(contactFormData.email)) {
        setPostError("Invalid email address");
        return;
      }
      postForm(contactFormData.firstName,
        contactFormData.lastname,
        contactFormData.email,
        contactFormData.message, () => {
          setPostSuccess("Message sent successfully! We will get back to you soon.");
          setTimeout(() => {
            setPostSuccess("");
          }, 3000);
          setContactFormData({
            firstName: "",
            lastname: "",
            email: "",
            message: "",
          });
        }, () => {
          setPostError("Failed to send message, please try again later");
        });
    } else {
      setPostError("Please fill required fields");
      return
    }
  };
  return (
    <>
      <div className="contact-form">
        <div className="mb-4">
          <div className="mb-2">
            <h4>Leave us a message</h4>
          </div>
          <p>And we will get back to you as soon as possible.</p>
        </div>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-4">
              <input
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={handleChange}
                value={contactFormData.firstName}
              />
            </Col>
            <Col md={6} className="mb-4">
              <input
                type="text"
                placeholder="Last Name"
                name="lastname"
                onChange={handleChange}
                value={contactFormData.lastname}
              />
            </Col>
            <Col md={12} className="mb-4">
              <input
                type="text"
                placeholder="Email Address"
                name="email"
                onChange={handleChange}
                value={contactFormData.email}
              />
            </Col>
            <Col md={12} className="mb-4">
              <textarea
                placeholder="Your Message..."
                name="message"
                onChange={handleChange}
                value={contactFormData.message}
              ></textarea>
            </Col>
          </Row>
          {postError && (
            <div className="error-box">
              {postError}
            </div>)}
          {postSuccess && (
            <div className="success-box">
              { postSuccess }
            </div>)}

          <div className="submit-wrap">
            <button type="submit" className="button button-lg">
              Send Message
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

function postForm(firstName, lastname, email, message, successCallback, errorCallback) {
  const body = template
    .replace("{NAME}", firstName)
    .replace("{SURNAME}", lastname)
    .replace("{EMAIL}", email)
    .replace("{MESSAGE}", message);

  axios.post(getString("contactUsFormUrl"), {
    from: getString("contactEmailFrom"),
    to: getString("contactEmailTo"),
    subject: getString("appLandingContactSubject"),
    body: body
  })
    .then(response => {
      console.log('Success:', response.data);
      successCallback();
    })
    .catch(error => {
      console.log('error:', error);
      errorCallback();
    });
}

function validateEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export default ContactForm;
