import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getString } from "../firebase";

const bannerImage = require("../assets/images/screenshot-black.png");
const appStore = require("../assets/images/appstore.png");
const playStore = require("../assets/images/playstore.png");


const SiteBanner = () => {
  return (
    <section className="site-banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            {/* - Banner Heading - */}
            <div className="banner-heading">
              <div className="banner-text mb-4">
                <div className="banner-title">
                  <h1>
                  Tours Genie:<br/>Rhodes Experience
                  </h1>
                  <h4>Authentic, Local, Experience.</h4>
                  
                </div>
                <div className="banner-description">
                  <p>
                  Your go-to app for discovering the best tours, activities, and travel experiences on the beautiful island of Rhodes.
                  <br/>
                  <br/>
                  Download the Tours Genie App now and start your adventure today!
                  </p>
                </div>
              </div>

              <div className="banner-download-buttons">
                <Link to={ getString("AppStoreUrl")}>
                  <img src={appStore} alt="App Store" />
                </Link>
                <Link to={getString("playStoreUrl")}>
                  <img src={playStore} alt="Play Store" />
                </Link>
              </div>
            </div>
            {/* - Banner Heading - */}
          </Col>

          <Col lg={6} className="mt-lg-0 mt-5 d-lg-block d-none">
            {/* - Banner Image - */}
            <div className="banner-image">
              <img src={bannerImage} alt="Mobile Banner" style={{ height: "700px", padding: "20px" }}/>
            </div>
            {/* - Banner Image - */}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SiteBanner;
