import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import CeoMessage from "../components/CeoMessage";

const aboutBoxes = [
  {
    id: 1,
    icon: "las la-download",
    title: "Download the App",
    desc: "Start your Rhodes adventure with us now!",
  }
];

const About = () => {
  return (
    <section className="sp-t" id="about">
      <Container>
        <Row>
          <Col lg={8} className="mb-5 mx-auto text-center">
            {/* - Section Heading - */}
            <div className="section-heading">
              <div className="sub-heading">
                <p>Who we are?</p>
              </div>
              <div className="heading-title">
                <h2>Rhode's experts.</h2>
              </div>
              <div className="description">
                <p>
                We are the market experts in curated local tours, activities, experiences in Rhodes.
                </p>
                <p>
                Get the app today to explore and book handpicked activities and experiences crafted by knowledgeable local guides. <br/> Start your Rhodes adventure with us now!
                </p>
              </div>
            </div>
            {/* - Section Heading - */}
          </Col>
        </Row>

        {/* - About Data Display - */}
        {/* <Row>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}> 
          {(aboutBoxes || []).map((box) => (
            <Col key={box.id} lg={4} className="mb-lg-0 mb-4 text-center">

                <div className="iconbox-primary">
                <div className="item-icon mb-4">
                  <i className={box.icon}></i>
                </div>
                <div className="item-content">
                  <h3>{box.title}</h3>
                  <p>{box.desc}</p>
                </div>
                </div>
            </Col>
          ))}
          </div>
        </Row> */}
        {/* - About Data Display - */}

        {/* - CEO Message - */}
        {/* <Row className="mt-5">
          <Col md={12}>
            <CeoMessage />
          </Col>
        </Row> */}
        {/* - CEO Message - */}
      </Container>
    </section>
  );
};

export default About;
