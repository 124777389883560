import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";
import { getString as stringValue } from "firebase/remote-config";

const firebaseConfig = {
  apiKey: "AIzaSyCpQSvX6MyvkvCNoojDIUtvV-oqnv7PSH4",
  authDomain: "the-booking-platform-bff03.firebaseapp.com",
  projectId: "the-booking-platform-bff03",
  storageBucket: "the-booking-platform-bff03.appspot.com",
  messagingSenderId: "673796594168",
  appId: "1:673796594168:web:90d74aba1cd122f5aede0d",
  measurementId: "G-9LM3Y80G6F"
};

const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const configs = getRemoteConfig(app);

export const getString = (key) => {
  return stringValue(configs, key)
}