import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file for styling
import privacyPolicyContent from './PrivacyPolicy.md'; // Import the markdown file

class PrivacyPolicy extends React.Component {
  render() {
    return (
          <div className="privacy-policy">
            <h1>Privacy Policy</h1>
            <p><strong>Effective Date: February 1, 2024</strong></p>
      
            <h2>Introduction</h2>
            <p>
              Welcome to THE BOOKING PLATFORM LTD, a premier destination for discovering and booking unparalleled travel experiences. At THE BOOKING PLATFORM LTD ("we", "us", "our"), we prioritize the confidentiality, integrity, and security of your personal information. This Privacy Policy meticulously outlines our protocols for collecting, utilizing, safeguarding, and disclosing information gathered from our valued users ("you") through our diverse array of services, including our website, mobile applications, customer support channels, and interactive features (collectively referred to as the "Services").
            </p>
            <p>
              Your engagement with our Services signifies your acknowledgment and consent to the practices described in this Privacy Policy. Should our privacy practices not align with your expectations, we respectfully advise against the use of our Services.
            </p>
      
            <h2>Detailed Information Collection Practices</h2>
            <p>Our approach to data collection is holistic, aimed at delivering personalized and efficient services:</p>
            <ul>
              <li><strong>Comprehensive Identity and Contact Data:</strong> Beyond basic identification and contact details, we delve into the nuances of user preferences and service expectations.</li>
              <li><strong>In-depth Account and Security Information:</strong> Secure login credentials, account settings, and user preferences form the backbone of our personalized service offerings.</li>
              <li><strong>Financial Interactions and Payment Details:</strong> A detailed account of transactions, including payment methods, billing addresses, and purchase history, ensures transparency and trust.</li>
              <li><strong>Enhanced Device and Usage Insights:</strong> Advanced analytics on device types, operating systems, browser types, and network information allow us to optimize our Services for various platforms.</li>
              <li><strong>Rich Engagement and Interaction Data:</strong> From page views and navigation patterns to interaction times and content preferences, we gather comprehensive usage statistics.</li>
              <li><strong>Content Contributions and User Feedback:</strong> We encourage and collect user-generated content, such as detailed reviews, rich media uploads, and direct feedback, to foster a vibrant community.</li>
              <li><strong>Extensive Third-Party Collaborations:</strong> Our partnerships with social media networks, marketing platforms, and analytics providers contribute additional layers of data, enriching our service personalization efforts.</li>
            </ul>
      
            <h2>Purpose-Driven Information Utilization</h2>
            <p>We leverage your information to:</p>
            <ul>
              <li>Craft and refine user-centric services, ensuring a seamless and engaging user experience.</li>
              <li>Personalize communication and marketing efforts, aligning them with your interests and preferences.</li>
              <li>Conduct in-depth analytics and research to identify trends, usage patterns, and potential service enhancements.</li>
              <li>Facilitate secure and efficient transaction processing, including bookings, refunds, and customer support interactions.</li>
              <li>Uphold legal, regulatory, and compliance obligations, ensuring adherence to applicable laws and industry standards.</li>
            </ul>
      
            <h2>Responsible Information Sharing Framework</h2>
            <p>Our information sharing practices are governed by principles of necessity, transparency, and consent:</p>
            <ul>
              <li><strong>Strategic Partnerships with Service Providers:</strong> We entrust a select group of third-party service providers with specific operational tasks, under strict confidentiality and security obligations.</li>
              <li><strong>Marketing and Promotional Activities:</strong> With your explicit consent, we engage in targeted marketing initiatives, sharing information with partners who adhere to our privacy standards.</li>
              <li><strong>Regulatory and Legal Compliance:</strong> In instances where legal obligations necessitate the sharing of information, we do so with the utmost regard for privacy and legal frameworks.</li>
              <li><strong>User-Consented Sharing:</strong> For scenarios outside our routine operations, we seek your direct consent before sharing your information, ensuring you retain control over your personal data.</li>
            </ul>
      
            <h2>Enhanced User Choices and Rights</h2>
            <p>We empower you with an array of rights and choices regarding your personal data:</p>
            <ul>
              <li>Comprehensive access to review, modify, or update your personal information at any time.</li>
              <li>Options to unsubscribe from marketing communications and adjust privacy settings to suit your preferences.</li>
              <li>Mechanisms to file complaints or inquiries regarding data usage, with a dedicated support team to address your concerns.</li>
            </ul>
      
            <h2>Advanced Data Security Measures</h2>
            <p>
              Our commitment to data security is unwavering, employing state-of-the-art technologies and protocols to safeguard your information against unauthorized access, disclosure, alteration, or destruction. Regular audits, continuous monitoring, and employee training are pillars of our security strategy.
            </p>
      
            <h2>Informed Consent and User Control</h2>
            <p>
              We believe in informed user consent, providing clear options for data collection, use, and sharing at every interaction point. Our Cookie Consent Tool and privacy settings enable you to tailor your experience according to your privacy preferences.
            </p>
      
            <h2>Periodic Policy Updates</h2>
            <p>
              To adapt to evolving privacy laws, user feedback, and service enhancements, we periodically update this Privacy Policy. Significant changes are communicated through various channels, including direct notifications and updated postings on our website.
            </p>
      
            <h2>Open Lines of Communication</h2>
            <p>
              For any inquiries, suggestions, or concerns regarding our Privacy Policy or your personal data, please reach out to us at:
            </p>
            <address>
              THE BOOKING PLATFORM LTD<br />
              378 GORGIE ROAD<br />
              MIDLOTHIAN, EDINBURGH<br />
              EH11 2RQ<br />
              Email: <a href="mailto:info@thebookingplatform.app">info@thebookingplatform.app</a>
            </address>
          </div>
    );
  }
}

export default PrivacyPolicy;
