import React from 'react';
import '../PrivacyPolicy/PrivacyPolicy.css';

class TermsAndConditions extends React.Component {

  render() {
    return (
      <div className="privacy-policy">
      <h1>THE BOOKING PLATFORM LTD General Terms and Conditions</h1>
      <p><strong>Effective Date: February 1, 2024</strong></p>

      <h2>Introduction</h2>
      <p>
        Welcome to THE BOOKING PLATFORM LTD, a premier online service facilitating the booking of travel experiences across the globe. Located at 378 GORGIE ROAD, MIDLOTHIAN, EDINBURGH EH11 2RQ, and operating the website www.thebookingplatform.app (the "Website") alongside our dedicated mobile application (the "App"), we bridge the gap between adventurous individuals and a vast selection of travel opportunities offered by third-party suppliers ("Suppliers"). Your access to and use of our Platform signifies your agreement to these Terms of Service ("Terms"), which incorporate our Privacy Policy, outlining your rights, obligations, and limitations.
      </p>

      <h2>1. Platform Usage Agreement</h2>
      <p>Your engagement with our Platform is governed by these comprehensive Terms. By accessing, browsing, or otherwise using our services, you affirm that you have read, understood, and consent to be bound by these Terms. Should you disagree with any part of these Terms, you must discontinue access or use of the Platform immediately.</p>

      <h2>2. User Responsibilities and Platform Use</h2>
      <p>
        <strong>2.1 Age and Responsibility:</strong> You affirm that you are at least 18 years old, possess the legal authority to enter into binding contracts, and will use the Platform in accordance with these Terms.
      </p>
      <p>
        <strong>2.2 Account Creation and Security:</strong> Creating an account enables enhanced interaction with our Platform. You are responsible for maintaining the confidentiality of your account details and for all activities under your account. We reserve the right to terminate accounts, remove content, or cancel orders at our discretion.
      </p>
      <p>
        <strong>2.3 Prohibited Activities:</strong> You are prohibited from using the Platform for unlawful purposes, infringing on intellectual property, causing harm to other users, or engaging in fraudulent activities. Violation of these prohibitions may result in immediate termination of your access to the Platform.
      </p>

      <h2>3. Bookings, Payments, and Financial Terms</h2>
      <p>
        <strong>3.1 Contractual Relationships:</strong> When you book an experience, you create a direct contract with the Supplier. We act as an intermediary platform, and our responsibilities are limited to facilitating the availability of the Platform and services.
      </p>
      <p>
        <strong>3.2 Payment Obligations:</strong> Full payment is required at the time of booking. You agree to bear any additional charges that may apply to your booking, including but not limited to taxes, service fees, and currency conversion fees.
      </p>
      <p>
        <strong>3.3 Cancellations and Refunds:</strong> Each booking is subject to the cancellation and refund policy of the respective Supplier. We encourage you to review these policies thoroughly before making a booking to understand your rights and obligations.
      </p>

      <h2>4. Intellectual Property Rights</h2>
      <p>
        <strong>4.1 Ownership and Use:</strong> The content, including text, graphics, images, and software, on our Platform is owned by THE BOOKING PLATFORM LTD or its licensors and is protected by copyright and intellectual property laws.
      </p>
      <p>
        <strong>4.2 User Contributions:</strong> Users may contribute to the Platform through reviews, comments, and other content. You grant us a non-exclusive, royalty-free license to use, reproduce, modify, and publish such contributions.
      </p>

      <h2>5. Disclaimers, Limitations of Liability, and Indemnification</h2>
      <p>
        <strong>5.1 Disclaimer of Warranties:</strong> Our Platform and services are provided "as is" without any warranties, express or implied. We do not guarantee the accuracy, reliability, or completeness of any content or information provided on the Platform.
      </p>
      <p>
        <strong>5.2 Limitation of Liability:</strong> THE BOOKING PLATFORM LTD shall not be liable for any direct, indirect, incidental, or consequential damages arising out of your use of the Platform or services.
      </p>
      <p>
        <strong>5.3 Indemnification:</strong> You agree to indemnify and hold harmless THE BOOKING PLATFORM LTD, its affiliates, officers, agents, and employees from any claim or demand made by any third party due to or arising out of your breach of these Terms, or your violation of any law or the rights of a third party.
      </p>

      <h2>6. Modifications to Terms and Platform</h2>
      <p>We reserve the right to modify these Terms and the Platform, including its features and services, at any time without prior notice. Your continued use of the Platform following any modification constitutes your acceptance of the new Terms.</p>

      <h2>7. Governing Law and Dispute Resolution</h2>
      <p>These Terms are governed by the laws of Scotland, United Kingdom. Any disputes arising from or relating to the Terms or the Platform will be subject to the exclusive jurisdiction of the Scottish courts.</p>

      <h2>8. Contact Information</h2>
      <p>For inquiries or concerns regarding these Terms or the Platform, please contact us at info@thebookingplatform.app.</p>

      <p>By using THE BOOKING PLATFORM LTD, you acknowledge that you have read, understood, and agreed to these extended Terms of Service, committing to abide by them fully.</p>
    </div>
    );
  }
}

export default TermsAndConditions;
