import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Home from "./views/Home";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./views/TermsAndConditions/TermsAndConditions";
import Preloader from "./components/Preloader";
import { fetchAndActivate } from "firebase/remote-config";

import { configs as remoteConfigs } from "./firebase";

function App() {
  remoteConfigs.settings.minimumFetchIntervalMillis = 1;
  const [isConfigFetched, setIsConfigFetched] = useState(false);
  useEffect(() => {

    fetchAndActivate(remoteConfigs)
      .then(() => {
        setIsConfigFetched(true);  
      })
      .catch((err) => {
        setIsConfigFetched(true);  
      });
  }, []);

  return (
    <Router>
      {!isConfigFetched && <Preloader /> }

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
